import * as React from 'react'
import { List, Datagrid, DateField, TextField } from 'react-admin'

export const name = 'users'

export const options = {
    label: 'Пользователи'
}

export const list = (props) => (
    <List {...props}  sort={{ field: 'created', order: 'DESC' }}>
        <Datagrid>
            <TextField source="id" />
            <TextField label="Имя" source="name" />
            <DateField label="Создан" source="created" showTime locales="ru-RU"/>
            <DateField label="Изменён" source="updated" showTime locales="ru-RU" />
        </Datagrid>
    </List>
)
