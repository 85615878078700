import * as React from "react";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link'
import GetAppIcon from '@material-ui/icons/GetApp';
import { Show, SimpleShowLayout, List, Datagrid, DateField, TextField, ChipField, FunctionField, useRecordContext } from 'react-admin';

export const name = 'torrents'

export const options = {
    label: 'Торренты'
}

const fileSize = size => {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    if(i > 0) {
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ'][i];
    }
    return '-'
}

const fileUrl = (id) => `/api/${name}/${id}/file`

const downloadFileComponent2 = (id) => (
    <Link download href={fileUrl(id)}><GetAppIcon /></Link>
)

export const list = (props) => (
    <List {...props} sort={{ field: 'created', order: 'DESC' }}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField label="Название" source="title" />
            <ChipField label="Источник" source="source" />
            <TextField label="Source Id" source="sourceId" />
            <FunctionField label="Размер" render={record => fileSize(record.size) }/>
            <DateField label="Создан" source="created" showTime locales="ru-RU"/>
            <DateField label="Изменён" source="updated" showTime locales="ru-RU" />
            <FunctionField label="Файл" render={record => downloadFileComponent2(record.id) }/>
        </Datagrid>
    </List>
);

const VideoField = ({ label, source, ...rest }) => {
    const record = useRecordContext();
    return (
        <>
            <Typography
                component="span"
                variant="label"
                {...rest}
            >
                <video width={640} heigth={460} controls>
                    <source src={`/api/torrents/${record[source]}/stream`} type="video/mp4"/>
                </video>
            </Typography>
        </>
    )
};


export const show = (props) => {
    console.log(props)
    return (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField label="Название" source="title" />
            <ChipField label="Источник" source="source" />
            <TextField label="Source Id" source="sourceId" />
            <VideoField label="Video" source="id"/>
        </SimpleShowLayout>
    </Show>
)}