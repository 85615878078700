import React from 'react';
import { fetchUtils, Admin, Resource } from 'react-admin';
// import jsonServerProvider from 'ra-data-json-server';
import restProvider from 'ra-data-json-server';

import authProvider from './authProvider'

import * as TorrentProps from './Torrent'
import * as UserProps from './User'

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`);
  }
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = restProvider('/api', httpClient);

// const dataProvider = jsonServerProvider('/module-users');
const App = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider}>
    <Resource {...(TorrentProps)} />
    <Resource {...(UserProps)} />
  </Admin>
);

export default App;
